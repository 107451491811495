<template>
    <b-card no-body>
        <b-overlay
            :show="show"
            rounded="sm"
        >
            <b-card-header>
                <h3>MMR</h3>
                <div>
                    <b-button variant="primary" @click="sendMMR"
                        ><feather-icon icon="SendIcon" /> Send MMR</b-button
                    >
                </div>
            </b-card-header>

            <b-card-body>
                <h5 class="mb-2">MAIN INFO</h5>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            label="Year"
                            label-for="h-first-name"
                            label-cols-md="4"
                        >
                            <v-select
                                id="h-first-name"
                                v-model="selectedYear"
                                @input="changeYear()"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="years"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Month"
                            label-for="h-first-name"
                            label-cols-md="4"
                        >
                            <v-select
                                id="h-first-name"
                                v-model="selectedMonth"
                                @input="changeMonth()"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="months"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Date Completed"
                            label-for="h-first-name"
                            label-cols-md="4"
                        >
                            <b-form-datepicker
                                id="h-first-name"
                                v-model="selectedDate"
                                class="mb-1"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Domicile"
                            label-for="h-first-name"
                            label-cols-md="4"
                        >
                            <v-select
                                v-model="selectedDomicile"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                label="title"
                                :options="option"
                                @input="onChange()"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Signature of Authorized Officer or Business Contact"
                            label-for="h-first-name"
                            label-cols-md="4"
                        >
                            <b-form-input  class="d-inline-block mr-1" v-model="signature" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <div></div>
            </b-card-body>
            <b-alert
                variant="danger"
                :show="showAlert"
                class="mb-0"
                >
                <div class="alert-body ">
                    <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                    />
                    <span class="float-right">Manual input(s) for mileage required.</span>
                    
                </div>
            </b-alert>
            <b-overlay
                :show="showUpdate"
                rounded="sm"
            >
            <b-card-body>
                <vue-good-table v-if="items.length"
                    class="position-relative fleet-table mmr_report_table"
                    :columns="fields"
                    :rows="items"
                    @on-selected-rows-change="selectionChanged"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    >

                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                        <span v-if="props.column.field === 'month'">
                            <v-select
                                id="h-first-name"
                                v-model="props.row.month"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="months"
                                style="width: 165px"
                            />
                        </span>
                        <span v-if="props.column.field === 'domicile'">
                            <b-form-input type="text" v-model="props.row.domicile"></b-form-input>
                        </span>
                        <span v-if="props.column.field === 'tractor_id'">
                            <b-form-input type="text" v-model="props.row.tractor_id"></b-form-input>
                        </span>
                        <span v-if="props.column.field === 'name'">
                            <b-form-input type="text" v-model="props.row.name"></b-form-input>
                        </span>
                        <span v-if="props.column.field === 'odometer'">
                            <b-form-input 
                                id="input-invalid2"
                                type="text" 
                                :state="props.row.odometer != null"
                                v-model="props.row.odometer">
                            </b-form-input>
                        </span>
                        <span v-if="props.column.field === 'repairs'">
                            <v-select
                                id="h-first-name"
                                v-model="props.row.repairs"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="['No', 'Yes']"
                                style="width: 110px"
                            />
                        </span>
                        <span v-if="props.column.field === 'out_of_service'">
                            <v-select
                                id="h-first-name"
                                v-model="props.row.out_of_service"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="['No', 'Yes']"
                                style="width: 110px"
                            />
                        </span>
                        <!-- <span v-if="props.column.field === 'domfp'">
                            <b-form-input 
                                type="text" 
                                v-model="props.row.domfp">
                            </b-form-input>
                        </span> -->
                        <span v-if="props.column.field === 'date'">
                            <b-form-input type="date" v-model="props.row.date"></b-form-input>
                        </span>
                        <span v-if="props.column.field === 'mmr_task'">
                            <v-select
                                id="h-first-name"
                                v-model="props.row.mmr_task"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="title"
                                :options="['No', 'Yes']"
                            />
                        </span>
                        <span v-if="props.column.field === 'domfp'">
                                <div v-on:click="ViewNotes(props.row.domfp)">
                                    <feather-icon
                                    icon="EyeIcon"
                                    class="mr-50"
                                    title="View Notes"
                                />
                                    </div>
                                    </span>
                        <span v-if="props.column.field === 'action'">
                            <b-button variant="outline-primary" @click="exportPDF(props)"
                                ><feather-icon icon="EyeIcon" /> </b-button
                            >
                        </span>
                    </template>

                </vue-good-table>
            </b-card-body>
            </b-overlay>
            <div class="hiddenContainer">
                <pdfexport ref="contentToExport" 
                    forcePageBreak=".page-break" 
                    :margin="'0.5cm'" 
                    :paper-size="'A2'" 
                    :keepTogether="'.prevent-split'"
                    :fileName = "downloadTractor + '-' + selectedMonth + ' ' + selectedYear + ' MMR'"
                    >
                    <PdfToPrint :data="mmrData" />
                </pdfexport>
            </div>
        </b-overlay>
        <b-modal
            size="lg"
            ref="NotesModal"
            id="NotesModal"
            centered
            title="View Notes"
            v-bind:hide-footer="true"
        >
        {{ notesPreview }}
        </b-modal>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormCheckbox,
    BPagination,
    BOverlay,
    BFormInvalidFeedback,
    BAlert
} from "bootstrap-vue";
import { VueGoodTable } from 'vue-good-table'
// import 'vue-good-table/dist/vue-good-table.css'
import { heightTransition } from "@core/mixins/ui/transition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import { PDFExport } from "@progress/kendo-vue-pdf";
import PdfToPrint from "./PdfToPrint.vue";

export default {
    components: {
        VueGoodTable,
        BCard,
        BCardHeader,
        BCardBody,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BRow,
        BCol,
        BButton,
        BFormDatepicker,
        BTable,
        BFormCheckbox,
        BPagination,
        vSelect,
        BOverlay,
        BFormInvalidFeedback,
        BAlert,
        pdfexport: PDFExport,
        PdfToPrint
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            years: [],
            selectedYear: null,
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            selectedMonth: null,
            completed: [],
            selectedCompleted: null,
            selectedDate: null,
            selectedSign: null,
            signs: [],
            selectedDomicile: [],
            items: [],
            fields: [
                {
                    field: "month",
                    label: "MONTH",
                    sortable: true,
                },
                {
                    field: "domicile",
                    label: "DOMICILE",
                    sortable: true,
                },
                
                {
                    field: "tractor_id",
                    label: "TRACTOR #",
                    sortable: true,
                },
                {
                    field: "name",
                    label: "BUSINESS NAME",
                    sortable: true,
                },
                {
                    field: "odometer",
                    label: "MILEAGE",
                    sortable: true,
                },
                {
                    field: "repairs",
                    label: "REPAIRS",
                    sortable: true,
                },
                {
                    field: "out_of_service",
                    label: "OOS",
                    sortable: true,
                },
               
                {
                    field: "date",
                    label: "DATE OF MAINTENANCE",
                    sortable: true,
                },
                {
                    field: "mmr_task",
                    label: "MAINTENANCE TASK",
                    sortable: true,
                },
                {
                    field: "domfp",
                    label: "NOTES",
                    sortable: true,
                },
                {
                    field: "action",
                    label: "View PDF",
                },
            ],
            nextTodoId: 2,
            forms: ["Yes", "No"],
            tractors: [],
            selectedOutOfService: null,
            selectedMaintenance: null,
            option: [],
            filter: null,
            filterOn: [],
            totalRows: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            show: false,
            showUpdate: false,
            showAlert: false,
            notesPreview:'',
            mmrData: {
                signature: '',
                monthYear: '',
                domicile: '',
                serviceName: '',
                odometer: '',
                tractor: '',
                mmrTask: '',
                oos: '',
                tArr: [
                    { id: 1, date: '', DOMFP: '' },
                    { id: 2, date: '', DOMFP: '' },
                    { id: 3, date: '', DOMFP: '' },
                    { id: 4, date: '', DOMFP: '' },
                    { id: 5, date: '', DOMFP: '' },
                ],
                dateCompleted: '',
            },
            signature: '',
            downloadTractor: ''
        };
    },
    mounted() {

        this.initTrHeight();
        // this.selectedMonth = new Date().toLocaleDateString("en-US", {
        //     month: "long",
        // });
        const date = new Date();
        this.selectedMonth = this.months[date.getMonth() - 1];
        const currentYear = date.getFullYear();
        this.selectedYear = currentYear;
        this.selectedDate = date;

        for (let i = currentYear - 3; i <= currentYear; i++) {
            this.years.push(i);
        }

        axios
            .post(`${serverUri}/schedule/gettractor`, {
                company: getUserData().company_id,
            })
            .then((res) => {
                let array = [];

                res.data.tractor.map((item) => {
                    array.push(item.tractor_id);
                });

                this.tractors = array;
            });

        axios
            .post(`${serverUri}/fleet/getfleet`, {
                company: getUserData().company_id,
            })
            .then((res) => {

                console.log(res.data);
                const that = this;
                const tempArr = [];
                res.data.map((item) => {
                    tempArr.push(item.domicile);
                });

                const unique = [...new Set(tempArr)];
                unique.forEach(function (item) {
                    that.option.push({ title: item });
                });
            });
    },
    created() {
        window.addEventListener("resize", this.initTrHeight);
    },
    destroyed() {
        window.removeEventListener("resize", this.initTrHeight);
    },
    methods: {


        ViewNotes(notes)
        {
            if(notes == '')
            {
                this.notesPreview = notes;
                this.$refs["NotesModal"].show();
            } else {
                alert('No notes found!')
            }

        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        repeateAgain() {
            this.items.push({
                id: (this.nextTodoId += this.nextTodoId),
            });

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight);
            });
        },
        removeItem(index) {
            this.items.splice(index, 1);
            this.trTrimHeight(this.$refs.row[0].offsetHeight);
        },
        initTrHeight() {
            this.trSetHeight(null);
            // this.$nextTick(() => {
            //     this.trSetHeight(this.$refs.form.scrollHeight);
            // });
        },

        selectionChanged(params) {
            this.selectedItems = params.selectedRows
        },

        changeYear() {
            this.getFilteredMMR();
        },

        changeMonth() {
            this.getFilteredMMR();
        },

        onChange() {
            this.getFilteredMMR();
        },

        getFilteredMMR() {
            if (this.selectedDomicile && this.selectedYear && this.selectedMonth) this.showUpdate = true;

            var arrayDomicile = [];
            this.selectedDomicile.map((item) => {
                arrayDomicile.push(item.title);
            });

            axios
                .post(`${serverUri}/fleet/filterfleet`, {
                    company: getUserData().company_id,
                    domicile: arrayDomicile,
                    year: this.selectedYear,
                    month: this.selectedMonth ? moment().month(this.selectedMonth).format("M") : '',
                })
                .then((res) => {
                    this.items = [];
                    this.showAlert = false;
                    this.showUpdate = false;
                    if (res.data.length) {
                        this.items = res.data.map((p) => {
                            if (!p.odometer) {
                                this.showAlert = true;
                            }

                            return {
                                ...p,
                                date: moment(p.date).format("YYYY-MM") == moment(this.selectedYear+"-"+this.selectedMonth).format("YYYY-MM") 
                                    ? p.date : "",
                                month: this.selectedMonth,
                                selected: false,
                                out_of_service: p.out_of_service == 1 ? "Yes" : "No",
                                repairs: p.mmr_task == 1 ? "Yes" : "No",
                                mmr_task: p.mmr_task == 1 ? "Yes" : "No",
                                odometer: Math.floor(p.odometer)
                            };
                        });

                        this.totalRows = this.items.length;
                    }
                                 
                });
        },

        exportPDF(data) {
            axios.post(`${serverUri}/fleet/getselectedmaintenance`, {
                tractor_id: data.formattedRow.tractor_id,
                domicile: data.formattedRow.domicile,
                month: moment().month(data.formattedRow.month).format("M")
            })
            .then(async (res) => {
                var temp = await res.data;

                if (temp.length) {
                    this.mmrData = {
                        signature: this.signature,
                        monthYear: this.selectedMonth + " " + this.selectedYear,
                        domicile: data.formattedRow.domicile,
                        serviceName: data.formattedRow.name,
                        odometer: data.formattedRow.odometer ? Math.round(data.formattedRow.odometer) : '',
                        tractor: data.formattedRow.tractor_id,
                        mmrTask: data.formattedRow.mmr_task,
                        oos: data.formattedRow.out_of_service,
                        tArr: temp,
                        dateCompleted: moment(this.selectedDate).format("MM/DD/YYYY"),
                    }

                    console.log(this.mmrData);

                    this.downloadTractor = data.formattedRow.tractor_id;
                    
                    setTimeout(() => {
                        this.$refs.contentToExport.save();
                    }, 1000)                   
                    
                }
            })
        },

        sendMMR() {
            if (this.selectedItems) {
                this.show = !this.show;

                const getNonEmailItems = this.selectedItems.filter((item) => {
                    return item.domicile_email == '' || item.domicile_email == null || item.domicile_email == 'null';
                });

                if (getNonEmailItems.length) {
                    getNonEmailItems.map((item) => {
                        this.showToast("warning", "warning", item.domicile + "/" + item.tractor_id + " has no domicile email.");
                    })
                }

                const newItems = this.selectedItems.map((item) => {
                    return {
                        ...item,
                        year: this.selectedYear,
                        monthYear: this.selectedMonth + " " + this.selectedYear,
                        dateCompleted: moment(this.selectedDate).format(
                            "YYYY-MM-DD"
                        ),
                        out_of_service: item.out_of_service == "Yes" ? 1 : 0,
                        mmr_task: item.mmr_task == "Yes" ? 1 : 0,
                        repairs: item.mmr_task == "Yes" ? 1 : 0,
                        signature: this.signature
                    };
                });

                axios
                    .post(`${serverUri}/fleet/sendmmr`, {
                        selectedItems: newItems,
                    })
                    .then((res) => {
                        this.show = !this.show;
                        this.showToast("success", "", res.data);
                    });
            } else {
                this.showToast("warning", "warning", "Please select domicile.");
            }
        },

        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: "BellIcon",
                    text: text,
                    variant,
                },
            });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.hiddenContainer {
    position: absolute;
    left: -10000px;
    top: 0;
}

.mmr_report_table .vgt-table th {
    font-size: 11px;
    border-bottom: 2px solid #ebe9f1;
    vertical-align: top;
    text-transform: uppercase;
    /* font-size: 0.857rem; */
    letter-spacing: 0.5px;
    padding: 0.72rem 1rem;
    background-color: #f3f2f7;
}
.mmr_report_table td.vgt-left-align {
    text-align: center;
}

</style>
