<template>
    <div class="mmr-body">
        
        <div class="contain">
            <h5 class="page-title">
                <strong>
                    U.S. Monthly Maintenance Record, MGBA-355
                </strong>
            </h5>
            <p class="text-right"><strong>30 September 2022</strong></p>
            <p class="block-1 f-20 lh-22">
                To comply with U.S. federal regulations, this form must be completed, signed and submitted to FedEx Ground by the 20th 
                of the month following the month for which inspections, repairs, or maintenance were performed on any service provider- 
                owned or -leased equipment. Submit one record for each piece of equipment, even if not regularly providing services.
            </p>

            <!-- main info -->
            <table class="w-100 my-10">
                <tr>
                    <td class="w-45 f-20">
                        <strong>Maintenance Record for the Month and Year of:</strong>
                    </td>
                    <td class="w-10"></td>
                    <td class="w-45 f-20">
                        <strong>Domicile Station/Hub:</strong>
                    </td>
                </tr>
                <tr>
                    <td class="ptb-5 border pl-10">
                        {{ data.monthYear }}
                    </td>
                    <td></td>
                    <td class="ptb-5 border pl-10">
                        {{ data.domicile }}
                    </td>
                </tr>
                <tr>
                    <td class="w-45 f-20 pt-10">
                        <strong>Service Provider Business Name:</strong>
                    </td>
                    <td class="w-10"></td>
                    <td class="w-45 f-20 pt-10">
                        <strong>Current Mileage</strong>
                    </td>
                </tr>
                <tr>
                    <td class="ptb-5 border pl-10">
                        {{ data.serviceName }}
                    </td>
                    <td></td>
                    <td class="ptb-5 border pl-10">
                       {{ data.odometer }}
                    </td>
                </tr>
                <tr>
                    <td class="w-45 f-20 pt-10">
                        <strong>Vehicle Unit #:</strong>
                    </td>
                    <td class="w-10"></td>
                    <td class="w-45 f-12 pt-10 pb-0" rowspan="2">
                        *If reading has decreased due to odometer repair/replacement, proof
                        should also be provided. If unit is undergoing repair and unavailable,
                        “N/A” may be utilized for current mileage.
                    </td>
                </tr>
                <tr>
                    <td class="ptb-5 border pl-10">
                        {{ data.tractor }}
                    </td>
                    <td></td>
                </tr>
            </table>

            <!-- has maintenances -->
            <table class="w-100 mb-10 mt-30">
                <tr>
                    <td class="w-70 f-20 pt-10">
                        Were any repairs, inspections or preventative maintenance performed on this unit?
                    </td>
                    <td class="w-5 f-20 pt-10 text-right">
                        <input type="checkbox" v-if="data.mmrTask == 'Yes'" checked
                                style="width: 20px; height: 20px; padding-right: 10px;" /> 
                        <input type="checkbox" v-if="data.mmrTask == 'No' || data.mmrTask == '' ||  data.mmrTask == null"
                                style="width: 20px; height: 20px; padding-right: 10px;" /> Yes
                    </td>
                    <td class="w-5 f-20 pt-10 text-right">
                        <input type="checkbox" v-if="data.mmrTask == 'No'" checked
                                style="width: 20px; height: 20px; padding-right: 10px;" /> 
                        <input type="checkbox" v-if="data.mmrTask == 'Yes' || data.mmrTask == '' ||  data.mmrTask == null"
                                style="width: 20px; height: 20px; padding-right: 10px;" /> No
                    </td>
                </tr>
            </table>

            <!-- out of service -->
            <table class="w-100 mb-20 mt-30">
                <tr>
                    <td class="w-70 f-20 lh-22 pt-10">
                        If “no” maintenance was performed, was the unit out of service and unable to provide
                        service (i.e., awaiting repair, on litigation hold, etc.)?
                    </td>
                    <td class="w-5 pt-0 f-20 text-right" v-if="data.mmrTask == 'No'">
                        <input type="checkbox" v-if="data.oos == 'Yes'" checked
                            style="width: 20px; height: 20px; padding-right: 10px;" />
                        <input type="checkbox" v-if="data.oos == 'No' || data.oos == ''  || data.oos == null"
                            style="width: 20px; height: 20px; padding-right: 10px;" /> Yes
                    </td>
                    <td class="w-5 pt-0 f-20 text-right" v-if="data.mmrTask == 'No'">
                        <input type="checkbox" v-if="data.oos == 'Yes' || data.oos == ''  || data.oos == null"
                            style="width: 20px; height: 20px; padding-right: 10px;" />
                        <input type="checkbox" v-if="data.oos == 'No'" checked
                            style="width: 20px; height: 20px; padding-right: 10px;" /> No
                    </td>

                    <td class="w-5 pt-0 f-20 text-right" v-if="data.mmrTask == 'Yes'">
                        <input type="checkbox" v-if="data.oos == 'Yes'" 
                            style="width: 20px; height: 20px; padding-right: 10px;" />
                        <input type="checkbox" v-if="data.oos == 'No' || data.oos == ''  || data.oos == null"
                            style="width: 20px; height: 20px; padding-right: 10px;" /> Yes
                    </td>
                    <td class="w-5 pt-0 f-20 text-right" v-if="data.mmrTask == 'Yes'">
                        <input type="checkbox" v-if="data.oos == 'Yes' || data.oos == ''  || data.oos == null"
                            style="width: 20px; height: 20px; padding-right: 10px;" />
                        <input type="checkbox" v-if="data.oos == 'No'" 
                            style="width: 20px; height: 20px; padding-right: 10px;" /> No
                    </td>
                </tr>
            </table>

            <!-- block 2 -->
            <p class="f-18 lh-20 mt-30">
                All repairs/replacements, or maintenance performed in conformance with a vehicle’s Maintenance Interval Form or any other major vehicle system must be reported on an MMR with detailed notations or attached receipts.
            </p>
            <p class="f-18 lh-20 mt-30">
                All notations must provide enough detail for a DOT official to determine what component(s), location(s), and type of work was performed (e.g., replace, repair, adjust, etc.).
            </p>
            <p class="f-18 lh-20 mt-30">
                Annual Federal/State and Pre/Post trip inspections must not be reported on the MMR, however repairs and maintenance of components that resulted from these inspections must be reported on an MMR.
            </p>
            <p class="f-18 lh-20 mt-30">
                General maintenance (e.g., oil/filter changes, lubrication, adjustments) should be reported with adequate detail to clearly convey what components were repaired or maintained. Abbreviations such as “LOF” or “PM” cannot be used, as these do not provide adequate details.
            </p>

            <table class="w-100 table-bordered f-20 mb-10 mt-30">
                <thead>
                    <tr>
                        <td class="ptb-5 pl-10 w-25">
                            <strong>Date of Maintenance</strong>
                        </td>
                        <td class="ptb-5 pl-10 w-75">
                            <strong>Nature/Description of Maintenance Performed</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data.tArr" :key="item.id" style="height: 35px;">
                        <td class="pl-10">{{ item.date ? getFormattedDate(item.date) : ''}}</td>
                        <td class="pl-10">{{ item.DOMFP ? item.DOMFP : '' }}</td>
                    </tr>
                </tbody>
            </table>

            <!-- block 3 -->
            <table class="w-100 mt-30 mb-10">
                <tr>
                    <td class="w-5 pt-0 f-20 align-top">
                        <input type="checkbox" checked>
                    </td>
                    <td class="w-95 f-20 lh-22">
                        By checking this box, I declare that this record is true and correct. Unless otherwise clearly indicated as “out of
                        service” on this record, I confirm that the equipment on this record is in compliance with the Federal Motor Carrier
                        Safety Regulations 49 C.F.R. 396.3(a)(1) and 396.7 (a) and is in safe operating condition and meets all federal, state
                        and local motor vehicle laws. Furthermore, I confirm that preventative maintenance is consistent with the interval
                        schedule per 396.3(b)(2).
                    </td>
                </tr>
            </table>

            <!-- Signature -->
            <div class="w-100 signature-box mt-30">
                <table class="w-100">
                    <tr>
                        <td class="w-35 f-20 lh-22">
                            <strong>Signature of Authorized Officer or Business Contact:</strong>
                        </td>
                        <td class="w-8"></td>
                        <td class="w-35 f-20 lh-22">
                            <strong>Date Completed:</strong>
                        </td>
                    </tr>
                    <tr>
                        <td class="ptb-10 border text-center">
                            <span class="mt-bold">{{ data.signature }}</span>
                        </td>
                        <td></td>
                        <td class="ptb-10 border pl-10">
                            {{ data.dateCompleted }}
                        </td>
                    </tr>
                </table>
            </div>
            
            <!-- block 4 -->
            <p class="f-17 mt-30 font-italic" >
                * The Monthly Maintenance Record (MMR) is FedEx Ground's systematic method of obtaining vehicle maintenance records for
                service provider-owned vehicles in compliance with the Federal Motor Carrier Safety Regulations which require motor carriers to have
                a systematic method of causing vehicles operating under their motor carrier operating authority to be inspected, repaired, and
                maintained. Therefore, if FedEx Ground does not receive records for a vehicle by the 20th of the month following the month in which
                maintenance, repairs, or inspections were performed, packages will not be made available to this vehicle.
            </p>
            
            <table class="w-100 mt-30">
                <tr>
                    <td class="w-70 f-17 py-0" style="border-top: 1px solid #000;">
                        This form for service providers is accessed through mybizaccount.fedex.com
                    </td>
                    <td class="w-30 f-17 text-right" style="border-top: 1px solid #000;">
                        Page 1 of 1
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        data: Object
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("MM/DD/YYYY")
        },
    },
}
</script>


<style scoped>
    .mmr-body {
        margin: 20px;
    }

    p {
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .page-title {
        border: 2px solid #525252;
        background-color: #e9e9e9;
        width: 100% !important;
        text-align: center !important;
        box-sizing: border-box;
        font-size: 35px;
        padding: 3px;
    }
    .block-1 {
        font-size: 17px;
    }

    /* font sizes */
    .f-12 {
        font-size: 12px !important;
    }
    .f-17 {
        font-size: 17px !important;
    }
    .f-16 {
        font-size: 16px !important;
    }
    .f-18 {
        font-size: 18px !important;
    }
    .f-20 {
        font-size: 20px !important;
    }

    /* widiths */
    .w-100 {
        width: 100% !important;
        margin: 0;
        padding: 0;
    }
    .w-5 {
        width: 5% !important;
    }
    .w-8 {
        width: 8% !important;
    }
    .w-10 {
        width: 10% !important;
    }
    .w-15 {
        width: 15% !important;
    }
    .w-25 {
        width: 25% !important;
    }
    .w-30 {
        width: 30% !important;
    }
    .w-35 {
        width: 35% !important;
    }
    .w-40 {
        width: 40% !important;
    }
    .w-45 {
        width: 45% !important;
    }
    .w-50 {
        width: 50% !important;
    }
    .w-60 {
        width: 60% !important;
    }
    .w-70 {
        width: 70% !important;
    }
    .w-75 {
        width: 75% !important;
    }
    
    /* borders */
    .border {
        border: 1px solid grey !important;
    }

    /* margins */
    .mt-0 {
        margin-top: 0px !important;
    }
    .mt-10 {
        margin-top: 10px !important;
    }
    .mt-20 {
        margin-top: 20px !important;
    }
    .mt-30 {
        margin-top: 30px !important;
    }
    .mb-10 {
        margin-bottom: 10px !important;
    }
    .mb-20 {
        margin-bottom: 20px !important;
    }
    .my-5 {
        margin: 5px 0 !important;
    }
    .my-10 {
        margin: 10px 0 !important;
    }
    .my-20 {
        margin: 20px 0 !important;
    }
    
    /* paddings */
    .ptb-5 {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
    .ptb-10 {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        font-size: 25px;
    }
    .pt-10 {
        padding-top: 10px !important;
    }
    .pt-0 {
        padding-top: 0px !important;
    }
    .pb-0 {
        padding-bottom: 0px !important;
    }
    .pl-10 {
        padding-left: 10px !important;
    }

    /* line height */
    .lh-22 {
        line-height: 22px !important;
    }

    .contain {
        margin-top: 60px;
    }

    @page {
        margin-bottom:0px;
    }
</style>